.profile-in-post {
    display: flex;
    justify-content: left;
    width: 100%;
    max-width: calc(100% - 44px);

}

.profile-sub-icon {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 50%;
}

.bold {
    font-weight: 600;
}

#comment-content {
    background-color: #F0F2F5;
    border-radius: 8px;
    padding: 4px;
    max-width: calc(100% - 88px);
}

#comment-content-1 {
    overflow-wrap: anywhere;
}



.target-post-display {
    display: flex;
}
.target-post-display-1 {
    display: flex;
    /* font-size: 14px; */
}
.target-post-display-1-1 {
    margin: 0 6px;
    position: relative;
    /* bottom: 4px; */
}

.underline-hover:hover {
    text-decoration: underline;
}

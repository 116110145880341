.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }

  .modal-background {
    opacity: 0.5;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(215, 215, 215, 0.9);
  }

  .modal-content {
    background-color: white;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 440px;
    max-width: 660px;
    width: 40%;
    height: auto;
    padding: 40px 0 50px 0;
  }

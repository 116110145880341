.create-comment-form {
    width: calc(100% - 44px);
}

.create-comment-form>input:focus {
    outline-width: 0;
}

.create-comment-container {
    display: flex;
    padding: 10px
}

.create-comment-form>input {
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    background-color: #F0F2F5;
    border-radius: 8px;
    padding-left: 8px;
}

.comment-container {
    display: flex;
    flex-direction: column;
}

.individual-comment {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.edit-delete {
    display: flex;
    flex-direction: column;
    align-content: right;
}

.edit-delete>button {
    font-size: 11px;
    font-weight: bold;
    border-radius: 8px;
    border: transparent;
    margin: 3px 0px
}


#edit-comment-form {
    width: calc(100% - 44px);
    height: 38px;
}

#edit-comment-container {
    display: flex;
    padding: 10px
}

#edit-comment-form>input {
    padding: 0;
    border: 0;
    width: 100%;
    height: 36px;
    background-color: #F0F2F5;
    border-radius: 8px;
    padding-left: 8px;
}

#edit-comment-form>input:focus {
    outline-width: 0;
}

#edit-cancel-button {
    height: 36px;
    border: none;
    background-color: lightgrey;
    border-radius: 8px;
    padding: 0;
    width: 60px
}

#edit-comment-error {
    padding-left: 54px
}

#nav-bar {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    background-color: white;
    box-shadow: 0px 1px 2px lightgrey;
    z-index: 10000;
}

#nav-bar-right {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 140px;
}

#tzengbook-icon-logged-in {
    width: 120px;
    height: 36px;
    margin: 10px;
    object-fit: contain;
}


#profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: transparent;
}

#notification-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: transparent;
    background-image: url("../../assets/bell-icon.png");
    background-size: contain;
    color: red;
    position: relative;
    padding-left: 28px;
    padding-bottom: 28px;
    font-weight: bold;
    font-size: 16px;
}

#notification-dropdown {
    position: fixed;
    top: 66px;
    right: 12px;
    background-color: white;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);
    max-height: 80vh;
}

#notification-title {
    font-size: 16px;
    font-weight: bolder;
    padding: 10px;
    text-decoration: underline;
}

.notification-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    line-height: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgb(0 0 0 / 10%);
}

.notification-card:hover {
    background-color: #F0F2F5;
}


.notification-card-top {
    display: flex;
    font-size: 14px;
}

.notification-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.notification-name {
    font-weight: bold;
    margin-left: 12px;
    margin-right:4px;
}
.notification-name:hover {
    text-decoration: underline;
}

.request-options {
    width: 100%;
    display: flex;
    justify-content: center;
}

.request-options > button{
    border: none;
    font-weight: bold;
    width: 104px;
    height: 36px;
    border-radius: 8px;
    margin: 4px 10px 0 10px
}
.request-options > button:hover {
    box-shadow: 0 8px 16px rgb(0 0 0 / 20%);

}

.accept-button {
    background-color: #1B74E4;
    color: white
}

.decline-button {
    background-color: lightgrey;
}

#no-requests {
    padding-left: 20px;
}

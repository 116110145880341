#profile-container {
    min-height: calc(100vh - 56px);
    height: 100%;
    display: flex;
}

#profile-left {
    min-width: 60px;
    width: 60px;
    height: calc(100vh - 57px);
    border-right: 1px solid lightgrey;
    position: sticky;
    top: 56px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-left-icons {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 20px;
}

#profile-left-spacer {
    background-color: red;
    border-bottom: 1px solid lightgrey;
    margin-top: 18px;
    width: 100%
}

.profile-left-icons:hover {
    box-shadow: 0 8px 16px rgb(0 0 0 / 30%);
}

#profile-left>div {
    align-self: center;

}

#profile-right {
    width: 100%;
    min-width: 1080px;
    overflow-y: auto;
    height: calc(100vh - 56px);
    height: 100%;
    display: flex;
    flex-direction: column;
}

#profile-header-container {
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    height: 240px;

}

#profile-header {
    border-bottom: 1px solid lightgrey;
    height: 200px;
    width: 1200px;
    min-width: 1080px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

#edit-profile-image {
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: 32px;
    left: 116px;
    background-color: lightgrey;
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#edit-profile-image:hover {

    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
}

#edit-profile-image-label {
    font-size: 20px;
    line-height: 0px;
    /* background-color: orange; */
}

#profile-pic {
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

#profile-name {
    width: calc(100% - 560px);
    padding: 20px;
    font-size: 36px;
    font-weight: bold;
}

#profile-body {
    min-height: calc(100vh - 357px);
    min-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    background-color: #F0F2F5;
}

#profile-body-right {
    height: 100%;
    min-width: 660px;
    width: 700px;
}


#profile-body-left {
    margin-top: 16px;
    padding: 8px;
    width: 484px;
}

.profile-body-left-content {
    background-color: white;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px lightgrey;
    padding: 12px;
}

#intro-title {
    font-size: 18px;
    font-weight: bold;
}

#edit-bio-form {
    width: 418px;
    height: 40px;
    border: none;
    padding: 4px 8px;
    resize: none;
    border-radius: 8px;
    text-align: left;
    vertical-align: top;
}
#edit-bio-form::-webkit-scrollbar {
    width: 8px;
}
#edit-bio-form::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 10px;
}

.intro-content {
    display: flex;
    margin: 16px;
    flex-direction: column;
}

.intro-content-sub {
    display: flex;
}

.intro-content-buttons {
    width: 100%;
    height: 32px;
    border: none;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    box-shadow: 0 0px 4px rgb(0 0 0 / 20%);
}

.intro-content-buttons:hover {
    background-color: lightgrey;
}

.intro-info-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0 10px;
}

.intro-info-content {
    font-size: 12px;
}

#profile-friend-sample-top {
    display: flex;
    justify-content: space-between;
}

#profile-friend-sample-top-title {
    font-size: 18px;
    font-weight: bold;
}

#profile-friend-sample-count {
    margin: 6px 0 10px 0;
    color: grey;
    font-size: 17px;

}

#see-all-friends {
    color: #216FDB;
    font-size: 17px;
}

#all-post-container {
    min-height: calc(100vh - 56px);
    height: 100%;
    background-color: #F0F2F5;
    display: flex;
    justify-content: space-between;
    z-index: 0;
}

#all-post-left {
    width: 300px;
    max-width: 300px;
    padding: 16px;
    height: calc(100vh - 56px);
    position: sticky;
    left: 0px;
    top: 56px;
}

#all-post-left>div:hover {
    cursor: pointer;
    background-color: lightgrey;

}

.all-post-left-contents {
    width: 100%;
    display: flex;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.all-post-left-contents>img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
}

.all-post-left-contents>div {}

#all-post-middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F0F2F5;
}

.create-comment-div {
    width: 680px;
    height: 60px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 2px lightgrey;
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.create-comment-div>img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.create-comment-div>button {
    background-color: #F0F2F5;
    border: transparent;
    height: 40px;
    width: 90%;
    border-radius: 20px;
    color: grey;
    font-size: 18px;
}

.create-comment-div>button:hover {
    background-color: lightgrey;
}

.single-post {
    width: 680px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 2px lightgrey;
    margin: 24px 0 8px;
}

.single-post-top {
    display: flex;
    padding: 10px;
}

#single-post-content {
    padding: 10px;
    overflow-wrap: anywhere;
}




#all-post-right {
    width: 300px;

}


.post-image {
    width: 100%
}







.individual-comment {
    display: flex;

}


.spacer {
    margin: 16px 0;
    border-top: 1px solid lightgrey
}

.border {
  border: 1px solid black
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 800px;
  font-family: Arial, sans-serif;

}

.cursor {
  cursor: pointer;
}

.links:hover {
  text-decoration: underline black;
  box-shadow: 0 8px 16px rgb(0 0 0 / 10%);
  color: black
}

.not-allowed {
  cursor: not-allowed;
}

.grey {
  color: grey
}

.small-text {
  font-size: 14px;
  width: fit-content;
}

.error-handling {
  color: red;
  font-size: 11.5px;
  list-style-type: none;
  padding-left: 12px;
}

.shift-right-12px {
  padding-left: 12px
}

.larger-font {
  font-size: 32px;
}

.border {
  border: 1px solid red;
}

.border-blue {
  border: 1px solid blue;
}

.border-black {
  border: 1px solid black;
}

img {
  object-fit: cover;
}
